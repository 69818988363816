import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { fetchWithAuth } from "../utils/fetchWithAuth";
import { Subheading } from './Heading';
import { Input } from './Input';

import { useAppSelector } from '../hooks';

const DocumentSettings = () => {
    const activeDocumentId = useAppSelector((state) => state.documents.activeDocumentId);
    const currentDocument = useAppSelector((state) => state.documents.currentDocument);
    const { token } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [localPrompt, setLocalPrompt] = useState('');
    const [localMaxWordCount, setLocalMaxWordCount] = useState(0);

    useEffect(() => {
        if (activeDocumentId) {
            console.log('Fetching settings for document:', activeDocumentId);
            setLocalPrompt(currentDocument?.prompt || '');
            setLocalMaxWordCount(currentDocument?.maxWordCount || 0);
            setIsLoading(false);
        }
    }, [activeDocumentId, currentDocument?.maxWordCount, currentDocument?.prompt]);

    const updateSettings = async (field: string, value: string) => {
        try {
            await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/documents/settings`, token, {
                method: 'PATCH',
                body: JSON.stringify({ documentId: activeDocumentId, [field]: value })
            });
        } catch (error) {
            console.error('Error updating document settings:', error);
        }
    };

    const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPrompt = e.target.value;
        console.log('Prompt changed:', newPrompt);
        setLocalPrompt(newPrompt);
        updateSettings('prompt', newPrompt);
    };

    const handleMaxWordCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newMaxWordCount = parseInt(e.target.value, 10);
        console.log('Max Word Count changed:', newMaxWordCount);
        setLocalMaxWordCount(newMaxWordCount); // Update local state
        updateSettings('maxWordCount', newMaxWordCount.toString());
    };

    return (
        <div className="flex items-center space-x-4">
            <div className="flex flex-1 items-center space-x-2">
                <Subheading className="whitespace-nowrap text-sm lg:text-base">
                    Prompt
                </Subheading>
                <Input
                    placeholder="Enter the essay prompt here..."
                    value={!isLoading ? localPrompt : ''}
                    onChange={handlePromptChange}
                    className="flex-grow"
                ></Input>
            </div>
            <div className="flex items-center space-x-2 ml-auto">
                <Subheading className="whitespace-nowrap text-sm lg:text-base">
                    Max Word Count
                </Subheading>
                <div className="w-20">
                <Input
                    type="number"
                    placeholder="0"
                    value={!isLoading ? localMaxWordCount : 0}
                    onChange={handleMaxWordCountChange}
                ></Input>
                </div>
            </div>
        </div>
    );
};

export default DocumentSettings;
