// src/store/documentsSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import { Document, DocumentMetaInfo } from '../utils/types';

interface DocumentsState {
    documentsMetaInfo: DocumentMetaInfo[];
    activeDocumentId: string | null;
    currentDocument: Document | null;
    currentPrompt: string | null;
    currentMaxWordCount: string | null;
    editorText: string | null;
    isLoading: boolean;
    error: any;
}

const initialState: DocumentsState = {
    documentsMetaInfo: [],
    activeDocumentId: null,
    currentDocument: null,
    currentPrompt: null,
    currentMaxWordCount: null,
    editorText: null,
    isLoading: false,
    error: null,
};
type ApiData = {
    documentId: string | null;
    userId: string | undefined;
    token: string | null;
}

export const fetchDocument = createAsyncThunk(
    'document/fetchDocument',
    async (data: ApiData, { rejectWithValue }) => {
        const { documentId, userId, token } = data;
        try {
            const response = await fetchWithAuth(
                `${process.env.REACT_APP_PUBLIC_HOST}/api/documents?id=${documentId}&userId=${userId}`,
                token
              );
            
            return response.document;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        setDocumentsMetaInfo(state, action: PayloadAction<DocumentMetaInfo[]>) {
            state.documentsMetaInfo = action.payload;
        },
        setActiveDocumentId(state, action: PayloadAction<string | null>) {
            state.activeDocumentId = action.payload;
        },
        setCurrentDocument(state, action: PayloadAction<Document | null>) {
            state.currentDocument = action.payload;
        },
        setCurrentPrompt(state, action: PayloadAction<string | null>) {
            state.currentPrompt = action.payload;
        },
        setCurrentMaxWordCount(state, action: PayloadAction<string | null>) {
            state.currentMaxWordCount = action.payload;
        },
        setEditorText(state, action: PayloadAction<string | null>) {
            state.editorText = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchDocument.pending, (state, action) => {
            state.activeDocumentId = action.meta.arg.documentId
            state.isLoading = true;
            state.error = null;
          })
          .addCase(fetchDocument.fulfilled, (state, action) => {
            state.isLoading = false;
            state.currentDocument = action.payload;
          })
          .addCase(fetchDocument.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
          });
      },
});

export const {
    setDocumentsMetaInfo,
    setActiveDocumentId,
    setCurrentDocument,
    setCurrentPrompt,
    setCurrentMaxWordCount,
    setEditorText,
} = documentsSlice.actions;

export default documentsSlice.reducer;