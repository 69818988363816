// React and third-party libraries
import { useEffect, useState } from 'react';
import { FaCheckCircle } from "react-icons/fa";

// Local components
import { Button } from '../components/Button';
import { Divider } from '../components/Divider';
import { Heading } from '../components/Heading';

// Utilities and hooks
import { useAuth } from '../contexts/AuthContext';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import type { Metadata } from 'next';
import { User } from '../utils/types';

export const metadata: Metadata = {
  title: 'Subscription',
}

const handleSubscription = async (event: React.FormEvent, userId: string, token: string) => {
  event.preventDefault();

  const response = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/subscription/checkout`, token, {
    method: 'POST',
    body: JSON.stringify({ userId, lookup_key: 'monthly' }),
  });

  if (response.url) {
    window.location.href = response.url;
  }
};

const handlePortalRequest = async (event: React.FormEvent, userId: string, token: string) => {
  event.preventDefault();

  const response = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/subscription/portal`, token, {
    method: 'POST',
    body: JSON.stringify({ userId }),
  });

  if (response.url) {
    window.location.href = response.url;
  }
};

const ProductDisplay = ({ userId, token }: { userId: string, token: string }) => (
  <div>
    <Heading className="text-center">Upgrade to Mirai Plus</Heading>
    <Divider className="my-10 mt-6" />
    <section className="flex justify-center items-center gap-x-24">

      {/* Mirai Plus Monthly Option */}
      <div className="flex flex-col bg-white rounded-md justify-between py-8 px-8 min-h-96 border border-gray-200 shadow-md">
        <div className="flex">
          <div className="flex flex-col justify-center">
            {/* Pricing */}
            <h3 className="text-xl my-2 font-semibold">Mirai Plus</h3>
            <div className="flex items-baseline mt-2">
              <span className="text-2xl text-gray-700">$</span>
              <span className="text-4xl font-bold">9.99</span>
              <span className="text-lg ml-1 text-gray-700">
                USD/month
              </span>
            </div>

            {/* Description */}
            <div className="text-md mt-4">Achieve essay excellence with Mirai Plus</div>

            {/* Benefits */}
            <h4 className="text-md mt-4 font-semibold">Benefits</h4>
            <ul className="list-none ml-5 text-md">
              <li className="text-gray-600 mt-2 flex items-center">
                <FaCheckCircle className="mr-2 text-black" />
                Get 3,000 credits per month.
              </li>
              <li className="text-gray-600 mt-2 flex items-center">
                <FaCheckCircle className="mr-2 text-black" />
                Access to the Draft Essay feature.
              </li>
              <li className="text-gray-600 mt-2 flex items-center">
                <FaCheckCircle className="mr-2 text-black" />
                Priority support.
              </li>
            </ul>
          </div>
        </div>
        <form onSubmit={(e) => handleSubscription(e, userId, token)}>
          <input type="hidden" name="lookup_key" value="monthly" />
          <Button type="submit" className="w-full cursor-pointer mt-2" color="dark">
            {"Get Mirai Plus"}
          </Button>
        </form>
      </div>
    </section>
  </div>
);

const SuccessDisplay = ({ sessionId, token }: { sessionId: string, token: string }) => {
  return (
    <div>
      <Heading className="text-center">Upgrade to Mirai Plus</Heading>
      <Divider className="my-10 mt-6" />
      <section className="flex justify-center items-center gap-x-24">
        <div className="flex flex-col bg-white rounded-md justify-between p-4 border border-gray-200">
          <h3>Subscription to Mirai Plus successful! Thank you for your support!</h3>
        </div>
      </section>
    </div>
  );
};

const Message = ({ message }: { message: string }) => (
  <div>
    <Heading className="text-center">Upgrade to Mirai Plus</Heading>
    <Divider className="my-10 mt-6" />
    <section className="flex justify-center items-center gap-x-24">
      <div className="flex flex-col bg-white rounded-md justify-between p-4 border border-gray-200 shadow-md ">
        <h3>{message}</h3>
      </div>
    </section>
  </div>
);

const ManageSubscription = ({ userId, token }: { userId: string, token: string }) => {
  return (
    <div>
      <Heading className="text-center">Manage Your Subscription</Heading>
      <Divider className="my-10 mt-6" />
      <section className="flex justify-center items-center gap-x-24">
        <form onSubmit={(e) => handlePortalRequest(e, userId, token)}>
          <Button type="submit" className="w-full cursor-pointer mt-2" color="dark">
            Open Billing Portal
          </Button>
        </form>
      </section>
    </div>
  );
};

async function assignCustomerId(sessionId: string, user: User, authenticate: (token: string) => void, token: string) {
  await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/subscription/customerId`, token, {
    method: 'POST',
    body: JSON.stringify({ sessionId, userId: user?._id || ''}),
  });

  // Refresh user
  if (user && !user.subscribed) {
    authenticate(token);  
  }
}

export default function Subscription() {

  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const { user, authenticate, token } = useAuth();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      assignCustomerId(query.get('session_id') || '', user, authenticate, token || '');
      setSuccess(true);
      setSessionId(query.get('session_id') || '');
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "The order was canceled - please try again when you're ready! If you need any assistance, please contact us at miraizonewriting@gmail.com. We'll get back to you as soon as possible."
      );
    }
  }, [sessionId]);

  if (!success && message === '' && !user?.subscribed) {
    return <ProductDisplay userId={user?._id || ''} token={token || ''} />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} token={token || ''} />;
  } else if (message !== '') {
    return <Message message={message} />;
  } else {
    return <ManageSubscription userId={user?._id || ''} token={token || ''} />;
  }

}
