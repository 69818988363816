// Third-party libraries
import { Box, Modal, Typography } from '@mui/material';
import Tippy from '@tippyjs/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaClipboardList, FaRegEdit, FaSpinner } from 'react-icons/fa';
import { HiOutlineSparkles } from "react-icons/hi2";
import { useLocation } from 'react-router-dom';

// Local components
import { Button } from '../components/Button';
import DocumentSettings from '../components/DocumentSettings';
import EditableTitle from '../components/EditableTitle';
import Editor from '../components/Editor';
import { Divider } from '../components/Divider';

// Utilities and hooks
import { fetchWithAuth } from "../utils/fetchWithAuth";
import { useAuth } from '../contexts/AuthContext';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setCurrentCredits } from '../store/creditsSlice';
import { setCurrentDocument } from '../store/documentsSlice';

interface FeatureButtonProps {
    icon: React.ComponentType<{ className?: string }>;
    label: string;
    onClick: () => void;
    isEditing: boolean;
    currentCredits: number;
    tooltipContent: string;
}

function FeatureButton({ icon: Icon, label, onClick, isEditing, currentCredits, tooltipContent }: FeatureButtonProps) {
    return (
        <Tippy content={tooltipContent} placement="bottom" delay={[200, 100]} className="bg-white rounded-md p-2 border border-gray-300">
            <div>
                <Button
                    color="dark"
                    onClick={onClick}
                    disabled={currentCredits <= 0 || isEditing}
                    className="w-16 lg:w-24 text-sm"
                >
                    <Icon className="text-base lg:text-lg" />
                    <div className="hidden lg:inline">{label}</div>
                </Button>
            </div>
        </Tippy>
    );
}

function DocumentPage() {
    const { user, token } = useAuth();
    const dispatch = useAppDispatch();
    const { isLoading, currentDocument } = useAppSelector((state) => state.documents);
    const currentCredits = useAppSelector((state) => state.credits.currentCredits);
    const location = useLocation();

    const documentId = useMemo(() => {
        const id = new URL(window.location.href).pathname.split('/').pop();
        console.log("In use memo, read document ID is: ", id);
        return id === "" ? null : id;
    }, [location.pathname]);

    const handleFetchError = useCallback((error: unknown) => {
        const err = error as { response?: { status: number } };
        if (err.response && err.response.status === 401) {
            window.location.href = "/login";
        }
        console.error("Error:", error);
    }, []);

    const [isEditing, setIsEditing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [isAnalyzing, setIsAnalyzing] = useState(false);

    const editorContentRef = useRef<string>('');
    const editorContent = useAppSelector((state) => state.documents.editorText);

    useEffect(() => {
        editorContentRef.current = editorContent ?? '';
    }, [editorContent]);

    const updateDocument = async (id: string, text: string) => {
        const htmlText = text.replace(/\n/g, '<br>');
        const url = `${process.env.REACT_APP_PUBLIC_HOST}/api/documents`;
        const response = await fetchWithAuth(url, token, {
            method: 'PATCH',
            body: JSON.stringify({ id, text: htmlText })
        });
        console.log("The document to be set is: ", response.document);

        dispatch(setCurrentDocument(response.document));
    };

    const handleRevise = async () => {
        if (!currentDocument) return;

        setIsEditing(true);
        if (!user) return;

        try {
            const data = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/ai/reviseText`, token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ text: editorContentRef.current, documentId: currentDocument._id, userId: user._id })
            });
            if (data && data.text) {
                console.log(data);
                updateDocument(currentDocument._id, data.text);
                dispatch(setCurrentCredits(Math.max(0, currentCredits - data.creditUsage)));
            }
        } catch (error) {
            handleFetchError(error);
        } finally {
            setIsEditing(false);
        }
    };

    const handleAnalyze = async () => {
        if (!currentDocument) return;

        setIsEditing(true);
        setIsAnalyzing(true);

        if (!user) return;

        try {
            const data = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/ai/analyzeText`, token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ text: editorContentRef.current, documentId: currentDocument._id, userId: user._id })
            });
            console.log("In handleAnalyze, received data: ", data);
            if (data && data.text) {
                setModalContent(data.text);
                setModalOpen(true);
                dispatch(setCurrentCredits(Math.max(0, currentCredits - data.creditUsage)));
            }
        } catch (error) {
            handleFetchError(error);
        } finally {
            setIsEditing(false);
            setIsAnalyzing(false);
        }
    };

    const handlePolish = async () => {
        if (!currentDocument) return;

        setIsEditing(true);
        if (!user) return;

        try {
            const data = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/ai/polishText`, token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ text: editorContentRef.current, documentId: currentDocument._id, userId: user._id })
            });
            if (data && data.text) {
                console.log(data);
                updateDocument(currentDocument._id, data.text);
                dispatch(setCurrentCredits(Math.max(0, currentCredits - data.creditUsage)));
            }
        } catch (error) {
            handleFetchError(error);
        } finally {
            setIsEditing(false);
        }
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    console.log("%c The current document ID is:", "color:green", documentId);
    
    return (
        <div className="flex w-full h-full overflow-hidden">
            <div className="flex w-full flex-col relative h-full">
                <div className="w-full mt-1">
                    <EditableTitle />
                </div>

                {documentId && (
                    <div className="w-full mt-1">
                        <div className="flex flex-col lg:flex-row items-start lg:items-start lg:space-x-4">
                            <div className="flex space-x-4">
                                <FeatureButton
                                    icon={HiOutlineSparkles}
                                    label="Polish"
                                    onClick={handlePolish}
                                    isEditing={isEditing}
                                    currentCredits={currentCredits}
                                    tooltipContent={currentCredits <= 0 ? "You are out of credits. Subscribe to get more!" : "Fix grammar, spelling, and errors."}
                                />
                                <FeatureButton
                                    icon={FaRegEdit}
                                    label="Revise"
                                    onClick={handleRevise}
                                    isEditing={isEditing}
                                    currentCredits={currentCredits}
                                    tooltipContent={currentCredits <= 0 ? "You are out of credits. Subscribe to get more!" : "Reorganize and improve the entire essay."}
                                />
                                <FeatureButton
                                    icon={FaClipboardList}
                                    label="Analyze"
                                    onClick={handleAnalyze}
                                    isEditing={isEditing}
                                    currentCredits={currentCredits}
                                    tooltipContent={currentCredits <= 0 ? "You are out of credits. Subscribe to get more!" : "Get feedback on strengths and weaknesses."}
                                />
                            </div>

                            <div className="flex-grow mt-4 lg:mt-0 lg:ml-4 w-full">
                                <DocumentSettings />
                            </div>
                        </div>
                    </div>
                )}

                {documentId &&
                    <Divider className="my-5" soft />
                }

                <div className="flex flex-1">
                    {!isLoading && documentId && currentDocument ? (
                        <Editor />
                    ) : (
                        <></>
                    )}
                    {isEditing && (
                        <div className="absolute inset-0 flex flex-col items-center justify-center">
                            <FaSpinner className="text-black text-6xl animate-spin" />
                            {isAnalyzing && (
                                <div className="mt-2 px-8 py-4 bg-white bg-opacity-100 rounded-lg text-black text-center font-bold text-lg italic">
                                    This may take up to 30 seconds as we analyze your essay against top admit essays.
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <Modal
                    open={modalOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '90%',
                            maxHeight: '80vh',
                            overflowY: 'auto',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4
                        }}
                    >
                        <Typography id="modal-title" variant="h6" component="h2">
                            Comments and Suggestions
                        </Typography>
                        <div id="modal-description" style={{ marginTop: '16px' }} dangerouslySetInnerHTML={{ __html: modalContent }} />
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export { DocumentPage };
