import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks';
import { setDocumentsMetaInfo, setCurrentDocument } from '../store/documentsSlice';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import { useAuth } from '../contexts/AuthContext';

function EditableTitle() {
	const dispatch = useAppDispatch();
	const documentsMetaInfo = useAppSelector((state) => state.documents.documentsMetaInfo);
  const currentDocument = useAppSelector((state) => state.documents.currentDocument);
  const location = useLocation();
  const activeDocumentId =
    location.pathname.split("/").pop() === "editor"
      ? null
      : location.pathname.split("/").pop();
  const { token } = useAuth();
  const [title, setTitle] = useState(activeDocumentId ? (currentDocument?.title || 'Untitled Document') : 'Select a document to get started');


	const [previousTitle, setPreviousTitle] = useState(title);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (activeDocumentId && currentDocument) {
			setTitle(currentDocument?.title || 'Untitled Document');
		}
		else {
			setTitle('Select a document to get started');
		}
	}, [currentDocument, activeDocumentId]);

	const handleBlur = async () => {
		if (!currentDocument || !activeDocumentId || !token) {
			console.error('currentDocument is undefined');
			return;
		}
		setIsEditing(false);
		setIsLoading(true);
		setPreviousTitle(title);
		await updateTitleInDatabase(activeDocumentId, title, token);
		setIsLoading(false);
		dispatch(setCurrentDocument({
			_id: currentDocument._id,
			title: title,
			text: currentDocument.text,
			prompt: currentDocument.prompt,
			maxWordCount: currentDocument.maxWordCount,
			dateCreated: currentDocument.dateCreated
		}));

		const newDocumentsMetaInfo = documentsMetaInfo.map((document) => {
			if (document._id === activeDocumentId) {
				return { ...document, title: title };
			}
			return document;
		});
		
		dispatch(setDocumentsMetaInfo(newDocumentsMetaInfo));
	};

	const handleTitleClick = () => {
		if (activeDocumentId) {
			setIsEditing(true);
		}
	};

	return (
		<div>
			{isEditing ? (
				<input
					type="text"
					value={title}
					onChange={(e) => setTitle(e.target.value)}
					onBlur={handleBlur}
					autoFocus
					className="text-2xl font-bold w-full border-none focus:ring-0 px-0"
					style={{ margin: 0, padding: 0 }}
				/>
			) : (
				<h1
					onClick={handleTitleClick}
					className={`text-2xl font-bold w-full border-none focus:ring-0 px-0 ${
						!activeDocumentId ? 'cursor-default' : 'cursor-pointer'
					}`}
					style={{ margin: 0, padding: 0 }}
				>
					{isLoading ? previousTitle : title}
				</h1>
			)}
		</div>
	);
}

async function updateTitleInDatabase(documentId: string, title: string, token: string) {
	try {
		console.log("Updating title in database: ", documentId, title);
		await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/documents/title`, token, {
			method: 'PATCH',
			body: JSON.stringify({ id: documentId, title })
		});
		console.log("Title updated successfully");
	} catch (error) {
		console.error('Error updating title:', error);
	}
}

export default EditableTitle;
