import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import Tippy from '@tippyjs/react';
import { Button } from './Button';
import { Input } from './Input';
import { Textarea } from './Textarea';
import { useAuth } from '../contexts/AuthContext';

interface DraftEssayModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (title: string, prompt: string, maxWordCount: string, roughIdea: string) => void;
}

const DraftEssayModal: React.FC<DraftEssayModalProps> = ({ open, onClose, onSubmit }) => {
    const [title, setTitle] = useState('');
    const [prompt, setPrompt] = useState('');
    const [maxWordCount, setMaxWordCount] = useState('');
    const [roughIdea, setRoughIdea] = useState('');
    const { user } = useAuth();

    const handleSubmit = () => {
        if (!prompt || !maxWordCount) {
            alert('One or more fields are incomplete.');
            return;
        }
        onSubmit(title, prompt, maxWordCount, roughIdea);
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-h-[80vh] overflow-y-auto bg-white shadow-lg p-4 rounded-lg"
            >
                <h2 id="modal-title" className="mb-4 text-xl font-bold">Draft an Essay</h2>
                <div className="mb-4">
                    <h3 className="mb-2 font-medium">Title</h3>
                    <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter the document title for your essay"
                    />
                </div>
                <div className="mb-4">
                    <h3 className="mb-2 font-medium">Prompt</h3>
                    <Textarea
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        placeholder="Enter the prompt for your essay"
                        rows={2}
                    />
                </div>
                <div className="mb-4">
                    <h3 className="mb-2 font-medium">Max Word Count</h3>
                    <Input
                        value={maxWordCount}
                        onChange={(e) => setMaxWordCount(e.target.value)}
                        placeholder="Enter the maximum word count"
                        type="number"
                    />
                </div>
                <div className="mb-4">
                    <h3 className="mb-2 font-medium">Briefly describe your rough idea for the essay</h3>
                    <Textarea
                        value={roughIdea}
                        onChange={(e) => setRoughIdea(e.target.value)}
                        placeholder="Describe your rough idea for the essay"
                        rows={4}
                    />
                </div>
                <div className="inline-block w-auto">
                    {user?.subscribed ? (
                        <>
                            <Button
                                onClick={handleSubmit}
                                color="dark"
                            >
                                Submit
                            </Button>
                            <span className="ml-2 text-sm text-gray-500">(This may take up to 30 seconds. Thank you for your patience!)</span>
                        </>
                    ) : (
                        <Tippy content="Premium feature: subscribe to Mirai Plus to unlock." placement="bottom" delay={[200, 100]} className="bg-white rounded-md p-2 border border-gray-300">
                            <div>
                                <Button
                                    onClick={handleSubmit}
                                    color="dark"
                                    disabled={!user?.subscribed}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Tippy>
                    )}
                </div>
            </Box>
        </Modal>
    );
};

export default DraftEssayModal; 